import React, { Component } from "react"
import { Link, graphql, StaticQuery } from 'gatsby';
import '../styles/components/error.scss';
import '../styles/components/splash.scss';
import Seo from '../components/Seo';
import Img from 'gatsby-image';

class NotFoundPage extends React.Component {

  constructor( props ) {
  
    super( props );
    this.state = {
      slides: '',
    }
  
  }


  // createGallery( gallery1, gallery2, gallery3 ) {

  //   let galleries = [];

  //   galleries.push( gallery1 );
  //   galleries.push( gallery2 );
  //   galleries.push( gallery3 );

  //   // let selectedGallery = galleries[Math.floor(Math.random()*galleries.length)];

  //   // return selectedGallery;
  //   return galleries;

  // }

  render() {

    return (
      <>
        <StaticQuery 
          query={graphql`
            query Error {
                wp {
                  generalSettings {
                    title
                    description
                  }
                }
              }
          `}
          render={(error) => (
           <>
            <Seo 
              bodyClasses="bg:white nav-hidden" 
              siteTitle={error.wp.generalSettings.title}
              siteDescription={error.wp.generalSettings.description}
              title={"Page not found (404)"}
              />

              <div className={ 'flex error' }>
                <p>Page not found. Return to the <Link className={ 'active' } to={ '/' }>Homepage</Link>.</p>
              </div>

              <div className={ 'splash splash--extended' }>


              </div>
           </>
          )
          }
        />  
      </>
    );

  }

}

export default NotFoundPage